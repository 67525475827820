import React from 'react';
import {Route, withRouter, Redirect} from 'react-router-dom';
import util from "../../util/util";

function Login(props) {
    const {component:Component, ...rest} = props;
    if(util.isLogged()){
        return <Redirect to="/dashboard" />;
    }

    return (
      <Route {...rest}  render={()=>{
            return(
                <React.Fragment> 
                    <Component {...rest}  />
                </React.Fragment>
            )
        }} />
    );
}

export default withRouter(Login);